/* eslint-disable no-restricted-globals */
import { env } from "./env";
import devConstants from "./constants/dev";
import prodConstants from "./constants/prod";

export const isRunningTests = () => {
  return process.env.JEST_WORKER_ID !== undefined;
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const config: Record<string, any> = {
  beta: {
    projectId: devConstants.projectId,
    cresicorNextServiceURL: location.origin,
    analyticsServiceURL: location.origin,
    budgetServiceURL: location.origin,
    forecastServiceURL: location.origin,
    apiRootPath: `api/`,
    stytch: {
      publicToken: devConstants.stytchPublicToken,
    },
    celloCredentials: {
      productId: devConstants.celloProductId,
      scriptURL: devConstants.celloScriptUrl,
    },
  },
  dev: {
    projectId: devConstants.projectId,
    cresicorNextServiceURL: `http://localhost:8002`,
    analyticsServiceURL: `http://localhost:8081`,
    budgetServiceURL: `http://localhost:8080`,
    forecastServiceURL: `http://localhost:8080`,
    apiRootPath: `api/`,
    stytch: {
      publicToken: devConstants.stytchPublicToken,
    },
    celloCredentials: {
      productId: devConstants.celloProductId,
      scriptURL: devConstants.celloScriptUrl,
    },
  },
  prod: {
    projectId: prodConstants.projectId,
    cresicorNextServiceURL: location.origin,
    analyticsServiceURL: location.origin,
    budgetServiceURL: location.origin,
    forecastServiceURL: location.origin,
    apiRootPath: `api/`,
    stytch: {
      publicToken: prodConstants.stytchPublicToken,
    },
    celloCredentials: {
      productId: prodConstants.celloProductId,
      scriptURL: prodConstants.celloScriptUrl,
    },
  },
  sandbox: {
    projectId: devConstants.projectId,
    cresicorNextServiceURL: location.origin,
    analyticsServiceURL: location.origin,
    budgetServiceURL: location.origin,
    forecastServiceURL: location.origin,
    apiRootPath: `api/`,
    stytch: {
      publicToken: devConstants.stytchPublicToken,
    },
    celloCredentials: {
      productId: devConstants.celloProductId,
      scriptURL: devConstants.celloScriptUrl,
    },
  },
};

if (!isRunningTests()) {
  console.info(`using configuration for environment ${env}`);
}
export default config[env];
