import {
  BatchUpdatePromStatusMutation,
  CreatePromotionBatchMutation,
  CreatePromotionBatchMutationVariables,
  UpdatePromotionStatusMutation,
  UpdatePromotionStatusMutationVariables,
} from "components/PlanningNext/mutations.generated";
import {
  BatchUpdatePromStatusInput,
  LimitOffsetInput,
  PromotionOrder,
  UpdatePromotionStatusInput,
} from "__generated__/graphql";
import {
  FieldInfo,
  OptimisticMutationConfig,
  UpdatesConfig,
} from "@urql/exchange-graphcache";
import {
  PlanningNextColumnsDocument,
  PlanningNextColumnsQuery,
  PlanningNextColumnsQueryVariables,
  PlanningNextColumnsSeperatedDocument,
  PlanningNextColumnsSeperatedQuery,
  PlanningNextColumnsSeperatedQueryVariables,
  PromotionPartsFragmentDoc,
} from "components/PlanningNext/queries.generated";
import { remove, unionBy } from "lodash";

const QUERY_KEY = "promotions";
const PAGINATED_QUERY_KEY = "paginatedPromotions";

type UpdatePromStatusInputType = {
  filters: {
    contains: string;
    company?: {
      id: {
        exact: string;
      };
    };
    customer?: {
      id: {
        exact: string;
      };
    };
    product?: {
      id: {
        exact: string;
      };
    };
    status?: string;
    status_In?: string;
    order: PromotionOrder;
    limit?: number;
    offset?: number;
    productGroup?: string;
    fundType?: string;
    tags?: {
      id: {
        inList?: string[];
      };
    };
    firstReceivers?: {
      id: {
        inList?: string[];
      };
    };
    month?: {
      exact: number;
    };
    year?: {
      exact: number;
    };
    lineClosed?: boolean;
    hasAttachments?: boolean;
    hasContracts?: boolean;
    isStarred?: boolean;
  };
  pagination?: LimitOffsetInput;
};

type FieldInfoProm = FieldInfo & {
  arguments: UpdatePromStatusInputType;
};

type UpdatePromotionStatusMutationAugmented = {
  updatePromotionStatus: UpdatePromotionStatusMutation["updatePromotionStatus"] & {
    isOptimistic?: boolean;
  };
};

const promStatusUpdateConfig: Partial<UpdatesConfig>["Mutation"] = {
  // Updater function for updatePromotionStatus mutation,
  // Which adds the new prom in the new prom status list cache,
  // & removes it from the old one.
  updatePromotionStatus(
    result: UpdatePromotionStatusMutationAugmented,
    _args: { data: UpdatePromotionStatusMutationVariables },
    cache,
    _info
  ) {
    const { status, id, isOptimistic } = result.updatePromotionStatus;

    const { data: dataArgs } = _args;

    (cache.inspectFields("Query") as FieldInfoProm[])
      .filter((field) => field.fieldName === QUERY_KEY)
      .forEach((field) => {
        const { arguments: args } = field;

        // This is required because in order to update
        // the correct cache, we need the corresponding variables
        // which were used to fetch the query
        if (args?.filters?.company) {
          const vars = {
            contains: args.filters.contains,
            companyid: args.filters.company?.id.exact,
            order: args.order,
            status: args.filters.status,
            pagination: args.pagination,
            customerid: args.filters.customer?.id.exact,
            productid: args.filters.product?.id.exact,
            productGroupid: args.filters.productGroup,
            fundTypeid: args.filters.fundType,
            tagids: args.filters.tags?.id.inList,
            firstReceivers: args.filters.firstReceivers?.id.inList,
            lineStatus: args.filters.lineClosed,
            hasAttachments: args.filters.hasAttachments,
            hasContracts: args.filters.hasContracts,
            month: args.filters.month?.exact,
            year: args.filters.year?.exact,
            starred: args.filters.isStarred,
          };

          if (args?.filters?.status === status) {
            cache.updateQuery<
              PlanningNextColumnsSeperatedQuery,
              PlanningNextColumnsSeperatedQueryVariables
            >(
              {
                query: PlanningNextColumnsSeperatedDocument,
                variables: vars as PlanningNextColumnsSeperatedQueryVariables,
              },
              (data) => {
                const newData = unionBy(
                  [result.updatePromotionStatus],
                  data?.promotions,
                  "id"
                );

                return { promotions: newData };
              }
            );
          }

          if (args?.filters?.status === dataArgs?.fromStatus) {
            if (isOptimistic) {
              cache.updateQuery<
                PlanningNextColumnsSeperatedQuery,
                PlanningNextColumnsSeperatedQueryVariables
              >(
                {
                  query: PlanningNextColumnsSeperatedDocument,
                  variables: vars as PlanningNextColumnsSeperatedQueryVariables,
                },
                (data) => {
                  const newData = remove(
                    data?.promotions || [],
                    (prom) => prom.id !== id
                  );

                  return { promotions: newData };
                }
              );
            } else {
              cache.invalidate("Query", field.fieldName, field.arguments);
            }
          }
        }
      });
  },
  // Updater function for batchUpdatePromStatus mutation,
  // Which adds the new proms in the corresponding list cache,
  // & removes it from the old one.
  batchUpdatePromStatus(
    result: BatchUpdatePromStatusMutation,
    _args: { data: BatchUpdatePromStatusInput },
    cache,
    _info
  ) {
    const { data: dataArgs } = _args;

    if (dataArgs.updateAll) {
      (cache.inspectFields("Query") as FieldInfoProm[])
        .filter((field) => field.fieldName === PAGINATED_QUERY_KEY)
        .forEach((field) => {
          const { arguments: args } = field;

          if (
            dataArgs.fromStatus
              ?.toLowerCase()
              ?.startsWith(args.filters.status_In?.toLowerCase() || " ")
          ) {
            return cache.invalidate("Query", field.fieldKey);
          }

          if (
            dataArgs.status
              ?.toLowerCase()
              ?.startsWith(args.filters.status_In?.toLowerCase() || " ")
          ) {
            return cache.invalidate("Query", field.fieldKey);
          }

          return null;
        });
    }

    result.batchUpdatePromStatus.forEach((promotion) => {
      const { status } = promotion;

      (cache.inspectFields("Query") as FieldInfoProm[])
        .filter((field) => field.fieldName === PAGINATED_QUERY_KEY)
        .forEach((field) => {
          const { arguments: args } = field;

          if (args?.filters?.company) {
            const vars = {
              contains: args.filters.contains,
              companyid: args.filters.company.id.exact,
              order: args.order,
              statusIn: args.filters.status_In,
              limit: args.pagination?.limit,
              offset: args.pagination?.offset,
              status: args.filters.status,
              pagination: args.pagination,
              customerid: args.filters.customer?.id.exact,
              productid: args.filters.product?.id.exact,
              productGroupid: args.filters.productGroup,
              fundTypeid: args.filters.fundType,
              tagids: args.filters.tags?.id.inList,
              firstReceivers: args.filters.firstReceivers?.id.inList,
              lineStatus: args.filters.lineClosed,
              hasAttachments: args.filters.hasAttachments,
              hasContracts: args.filters.hasContracts,
              month: args.filters.month?.exact,
              year: args.filters.year?.exact,
              starred: args.filters.isStarred,
            };

            if (
              status
                .toLowerCase()
                .startsWith(args.filters.status_In?.toLowerCase() || " ")
            ) {
              cache.updateQuery<
                PlanningNextColumnsQuery,
                PlanningNextColumnsQueryVariables
              >(
                {
                  query: PlanningNextColumnsDocument,
                  variables: vars as PlanningNextColumnsSeperatedQueryVariables,
                },
                (data) => {
                  if (!data) {
                    return null;
                  }

                  const totalCount = data.promotions.pageInfo.totalCount + 1;

                  const newData = unionBy(
                    [promotion],
                    data?.promotions.results || [],
                    "id"
                  ).splice(0, args.pagination?.limit);

                  return {
                    promotions: {
                      ...(data?.promotions || {}),
                      pageInfo: {
                        ...(data?.promotions.pageInfo || {}),
                        totalCount,
                      },
                      results: newData,
                    },
                  };
                }
              );
            }

            if (
              dataArgs.fromStatus
                ?.toLowerCase()
                ?.startsWith(args.filters.status_In?.toLowerCase() || " ")
            ) {
              return cache.invalidate("Query", field.fieldKey);
            }
          }

          return null;
        });
    });
  },
  // Updater function for createPromotionBatchWithLines mutation,
  // Which refreshes Prom list
  createPromotionBatchWithLines(
    result: CreatePromotionBatchMutation,
    _args: { data: CreatePromotionBatchMutationVariables },
    cache,
    _info
  ) {
    (cache.inspectFields("Query") as FieldInfoProm[])
      .filter(
        (field) =>
          field.fieldName === QUERY_KEY ||
          field.fieldName === PAGINATED_QUERY_KEY
      )
      .forEach((field) => {
        const { arguments: args } = field;

        if (args?.filters?.company) {
          return cache.invalidate("Query", field.fieldKey);
        }

        return null;
      });
  },
};

// Optimistic function, which assumes the happy return value
// For the updatePromotionStatus mutation, against which the
// updater function in run till we get back actual network response.
// Note: The optimistic return value MUST contain all the fields
// returned by the actual mutation response
// https://formidable.com/open-source/urql/docs/graphcache/cache-updates/#optimistic-updates
const promStatusOptimisticConfig: OptimisticMutationConfig = {
  updatePromotionStatus(args: { data: UpdatePromotionStatusInput }, cache) {
    const data = cache.readFragment(PromotionPartsFragmentDoc, {
      id: args.data.id,
    });

    return {
      __typename: "PromotionNode",
      ...data,
      status: args.data.status,
      isOptimistic: true,
    };
  },
};

export { promStatusUpdateConfig, promStatusOptimisticConfig };
